import { QueryClient } from '@tanstack/react-query';

import { getAuthToken } from './auth';

export const queryClient = new QueryClient();

export const backendServerUrl = process.env.REACT_APP_API_URL || 'http://192.168.2.4:8080/';

export const loginFormUrl = backendServerUrl + 'api/v1/auth/login/';

export const registerFormUrl = backendServerUrl + 'api/v1/auth/registration/';

export const resendConfirmationEmailUrl = backendServerUrl + 'api/v1/auth/registration/resend-email/';

export const forgotPasswordUrl = backendServerUrl + 'api/v1/auth/password/reset/';

export const resetPasswordUrl = backendServerUrl + 'api/v1/auth/password/reset/confirm/';


export async function fetchDownloadExcel({ signal, currentPage, completed, startDate, endDate, filters }) {
    try {
        const access_token = getAuthToken();
        // const bufferedDate = new Date(startDate)
        // const formattedDateStart = `${bufferedDate.getMonth() + 1}/${bufferedDate.getDate()}/${bufferedDate.getFullYear()}`;
        const formattedDateStart = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`;
        const formattedDateEnd = `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
        const body = { versandbanhof: filters.ac_info_versandbanhof }
        const downloadUrl = backendServerUrl + 'api/v1/corrected-emails/export/?completed=' + completed + '&created_at_after=' + formattedDateStart + '&created_at_before=' + formattedDateEnd + '&sender=' + filters.sender + '&counterpart=' + filters.counterpart + '&remarks=' + filters.remarks + '&deliver_place=' + filters.deliver_place + '&auftrags_code=' + filters.auftrags_code + '&weighing_station=' + filters.weighing_station + '&zulauf_nr=' + filters.zulauf_nr + '&lieferant_nr=' + filters.lieferant_nr + '&cu=' + filters.cu

        const response = await fetch(downloadUrl, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'application/json',
            },
            signal: signal
        });

        if (!response.ok) {
            const error = new Error('Could not fetch excel file. Please try again later');
            error.code = response.status;
            error.info = await response.json();
            throw error;
        }

        // Get the blob (binary data) from the response
        const blob = await response.blob();

        // Create a link element, set its href to the blob, and trigger the download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        // Provide a name for the downloaded file
        link.setAttribute('download', 'data.xlsx');

        // Append the link to the document and click it to start the download
        document.body.appendChild(link);
        link.click();

        // Clean up - remove the link after the download
        link.parentNode.removeChild(link);

        // Revoke the object URL after use
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
}

export async function fetchAllCorretedEmails({ signal, currentPage, completed, startDate, endDate, filterText }) {
    const access_token = getAuthToken();
    // const bufferedDate = new Date(startDate)
    // const formattedDateStart = `${bufferedDate.getMonth() + 1}/${bufferedDate.getDate()}/${bufferedDate.getFullYear()}`;
    const formattedDateStart = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear()}`;
    const formattedDateEnd = `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
    let url = backendServerUrl + 'api/v1/corrected-emails/?page=' + currentPage + '&completed=' + completed + '&created_at_after=' + formattedDateStart + '&created_at_before=' + formattedDateEnd

    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not fetch corrected emails. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function fetchRefreshCodeEnrolledCompany({ id, signal }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/external_clients/' + id + '/update-password/'

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json'
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not fetch external users. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function fetchDeleteEnrolledCompany({ id, signal, is_active }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/external_clients/' + id + '/'
    const body = { is_active: !is_active }

    const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json'
        },
        signal: signal
    });

    if (response.status === 204) {
        console.log("deleted successfully")
        return
    }
    if (!response.ok) {
        const error = new Error('Could not fetch external users. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function fetchUpdateEnrolledCompany({ formData }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/external_clients/' + formData.id + '/'
    const buffer = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) => value !== ' ')
    );

    const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(buffer),
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        const error = new Error('Could not fetch external users. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function fetchCreateEnrolledCompany({ formData, signal }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/external_clients/create/'
    const buffer = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) => value !== '')
    );

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(buffer),
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json'
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not fetch external users. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function fetchExternalEnrolledCompanies({ signal }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/external_clients/create/'

    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not fetch external users. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function CreateExternalForm({ formData, id = 'noidfound', password = 'nopassfound' }) {
    const buffer = { ...formData, email_code: password }
    let url = backendServerUrl + 'api/v1/profiles/' + id + '/'
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(buffer),
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        const error = new Error('Could not create external email. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }
}

export async function fetchExternalListedCompanies({ signal }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/external_clients/excel/'

    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not fetch external passwort. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function fetchGenerateNewExternalPassword() {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/profiles/me/email-code/'

    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
    });
    // console.log(response)

    if (!response.ok) {
        const error = new Error('An error occurred while toggling email completed');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    return response;
}

export async function CreateNullPredictions(email_id) {
    let url = backendServerUrl + 'api/v1/predictions/'
    const access_token = getAuthToken();
    const body_data = {
        "email_uuid": email_id,
        "weight": "Nicht erkannt!",
        "product": "Nicht erkannt!",
        "transport_nr": "Nicht erkannt!",
        "counterpart": "Nicht erkannt!",
        "remarks": "Nicht erkannt!",
        "deliver_place": "Nicht erkannt!",
        "date_load": "Nicht erkannt!"
    }
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body_data),
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        const error = new Error('Could not create predictions. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function CreateNullEmail() {
    let url = backendServerUrl + 'api/v1/extracted-emails/create/'
    const access_token = getAuthToken();
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        const error = new Error('Could not create emails. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const { id } = await response.json();
        return id;
    }
}

export async function fetchDeleteForm({ id }) {
    const access_token = getAuthToken();
    const response = await fetch(backendServerUrl + `api/v1/corrected-emails/` + id + '/', {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 204) {
        console.log('204 error')
        return 'Delete successful';
    }

    if (!response.ok) {
        const error = new Error('An error occurred while creating complete email');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const { email } = await response.json();

    return email;
}

export async function fetchUpdateForm({ updateForm, id }) {
    const access_token = getAuthToken();
    const response = await fetch(backendServerUrl + `api/v1/corrected-emails/` + id + '/', {
        method: 'PATCH',
        body: JSON.stringify(updateForm),
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        const error = new Error('An error occurred while sending request for Updating form');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const { email } = await response.json();

    return email;
}

export async function fetchGenerateXml({ signal, id }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/corrected-emails/generateXML/' + id + '/'
    const body_data = { 'message_type': 'TPO' }

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body_data),
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not contact server. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const serverResponse = await response.json();
        return serverResponse;
    }
}

export async function fetchGenerateXmlUpdate({ signal, id }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/corrected-emails/generateXML/' + id + '/'
    const body_data = { 'message_type': 'TPOU' }

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body_data),
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not contact server. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const serverResponse = await response.json();
        return serverResponse;
    }
}

export async function fetchGenerateXmlCancel({ signal, id }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/corrected-emails/generateXML/' + id + '/'
    const body_data = { 'message_type': 'TPOC' }

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body_data),
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not contact server. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const serverResponse = await response.json();
        return serverResponse;
    }
}

export async function fetchEmailsPrediction({ signal, id }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/predictions/of_email/' + id

    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not fetch emails. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const { prediction } = await response.json();
        return prediction;
    }
}

export async function fetchEmails({ signal, currentPage, completed }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/extracted-emails/?page=' + currentPage + '&completed=' + completed

    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not fetch emails. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const { extracted_emails } = await response.json();
        return extracted_emails;
    }
}

export async function fetchAuftragscodes({ signal, currentPage }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/auftragscodes/'

    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not fetch emails. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const { auftragscodes } = await response.json();
        return auftragscodes;
    }
}

export async function fetchConfirmEmail({ key }) {
    let url = backendServerUrl + 'api/v1/auth/registration/verify-email/'

    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ "key": key }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        const error = new Error('Could not confirm email. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function fetchCorrectedEmailsOfEmail({ signal, email_uuid }) {
    const access_token = getAuthToken();
    let url = backendServerUrl + 'api/v1/corrected-emails/of_email/' + email_uuid

    const response = await fetch(url, {
        headers: {
            'Authorization': 'Bearer ' + access_token
        },
        signal: signal
    });

    if (!response.ok) {
        const error = new Error('Could not fetch corrected emails. Please try again later');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    } else {
        const result = await response.json();
        return result;
    }
}

export async function createCompleteEmail(eventData) {
    const access_token = getAuthToken();
    const response = await fetch(backendServerUrl + `api/v1/corrected-emails/`, {
        method: 'POST',
        body: JSON.stringify(eventData),
        headers: {
            'Authorization': 'Bearer ' + access_token,
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        const error = new Error('An error occurred while creating complete email');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const { corrected_emails } = await response.json();

    return corrected_emails;
}

export async function ToggleEmailCompleted(id) {
    const access_token = getAuthToken();
    const response = await fetch(backendServerUrl + `api/v1/extracted-emails/` + id + '/', {
        method: 'PATCH',
        headers: {
            'Authorization': 'Bearer ' + access_token,
        },
    });

    if (!response.ok) {
        const error = new Error('An error occurred while toggling email completed');
        error.code = response.status;
        error.info = await response.json();
        throw error;
    }

    const { extracted_email } = await response.json();

    return extracted_email;
}