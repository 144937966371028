import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import "@webscopeio/react-textarea-autocomplete/style.css";

import AuftragCodesSelector from './AuftragCodesSelector';
import './CompleteEmailForm.css';
import CompleteEmailFormWagons from './CompleteEmalFormWagons';


export default function CompleteEmailForm({ inputData, auftragscodesData, onSubmit, children }) {
    const [auf_code, setAuf_code] = useState()
    const [StationError, setStationError] = useState()


    const [formData, setFormData] = useState({
        versandbanhof: '',
        verwiegung: '',
        counterpart: '',
        vbf_nr: '',
        remarks: '',
        deliver_place: '',
        date_load: inputData?.date_load ?? '',
        auftrags_code: '',
        zulauf_nr: '',
        lieferant_nr: '',
        weighing_station: '',
        wagons: [],
    });

    function handleChange(index, event) {
        const { name, value } = event.target;
        const wagons = [...formData.wagons];
        wagons[index][name] = value;
        setFormData({ ...formData, wagons: wagons });
    };

    function addWagon() {
        setFormData({
            ...formData,
            wagons: [...formData.wagons, { wagon_nr: '', gewicht: '', produkt: '', mrn: '' }],
        });
    };

    function addPredictionWagon(wagonPrediction) {
        setFormData({
            ...formData,
            wagons: wagonPrediction.wagons,
        });
    };

    function deleteWagon(index) {
        // const wagons = [...formData.wagons];
        // wagons.pop();
        // setFormData({ ...formData, wagons });
        const wagons = [...formData.wagons];
        wagons.splice(index, 1);
        setFormData({ ...formData, wagons });
    };

    async function handleSubmit(event) {
        event.preventDefault();
        onSubmit(formData)
    }

    function validator(value, setValue) {
        if (value) {
            (/^\d+$/.test(value)) ? setValue() : setValue("Nur Zahlen erlaubt!")
        }
    }

    // Used to validate without interracting with the field
    // useEffect(() => {
    //     validator(formData.weighing_station, setStationError);
    // }, [])

    useEffect(() => {
        if (auf_code) {
            // console.log(auf_code)
            setFormData({
                ...formData,
                verwiegung: auf_code.verwiegung,
                versandbanhof: auf_code.versandbanhof,
                counterpart: auf_code.empfaenger,
                vbf_nr: auf_code.vbf_nr,
                deliver_place: auf_code.empfangsbahnhof,
                auftrags_code: auf_code.code
            })
        }
        // Ensure this effect runs only when 'auf_code' changes
        // console.log(auf_code)
    }, [auf_code]);

    useEffect(() => {
        // TODO if backend server default value for non found is changed, this also needs to be changed to not crash
        if (inputData) {
            let wagonsWeights = inputData.weight !== 'Nicht erkannt!' ? JSON.parse(inputData?.weight.replace(/'/g, '"')) : inputData.weight;
            let wagonsProducts = inputData.product !== 'Nicht erkannt!' ? JSON.parse(inputData?.product.replace(/'/g, '"')) : inputData.product;
            let wagonsTransportNumbers = inputData.transport_nr !== 'Nicht erkannt!' ? JSON.parse(inputData?.transport_nr.replace(/'/g, '"')) : inputData.transport_nr;
            let wagonPrediction = { wagons: [] }

            const wagonsWeightsIsArray = Array.isArray(wagonsWeights);
            const wagonsProductsIsArray = Array.isArray(wagonsProducts);
            const wagonsTransportNumbersIsArray = Array.isArray(wagonsTransportNumbers);

            const indexingNumber = wagonsTransportNumbersIsArray ? wagonsTransportNumbers.length : (wagonsProductsIsArray ? wagonsProducts.length : (wagonsWeightsIsArray ? wagonsWeights.length : false))

            if (indexingNumber) {
                for (let index = 0; index < indexingNumber; index++) {

                    const currentWagonWeight = wagonsWeightsIsArray ? (index < wagonsWeights.length ? wagonsWeights[index] :
                        wagonsWeights[wagonsWeights.length]) : wagonsWeights

                    const currentWagonProduct = wagonsProductsIsArray ? (index < wagonsProducts.length ? wagonsProducts[index] :
                        wagonsProducts[wagonsProducts.length]) : wagonsProducts

                    const currentWagonTransportNumber = wagonsTransportNumbersIsArray ? (index < wagonsTransportNumbers.length ? wagonsTransportNumbers[index] :
                        wagonsTransportNumbers[wagonsTransportNumbers.length]) : wagonsTransportNumbers

                    const buffer = { wagon_nr: currentWagonTransportNumber, gewicht: currentWagonWeight, produkt: currentWagonProduct, mrn: '' }
                    wagonPrediction = { wagons: [...wagonPrediction.wagons, buffer] }
                }
                addPredictionWagon(wagonPrediction)
            }
            else {
                const buffer = { wagon_nr: 'Nicht erkannt!', gewicht: 'Nicht erkannt!', produkt: 'Nicht erkannt!', mrn: '' }
                wagonPrediction = { wagons: [...wagonPrediction.wagons, buffer] }

                addPredictionWagon(wagonPrediction)
            }
        }
        else {
            addWagon();
        }
    }, [inputData]);

    return (
        <>
            <form id="complete-email-form" className='complete_email_form' onSubmit={handleSubmit}>
                <AuftragCodesSelector auftragscodesData={auftragscodesData} setAuf_code={setAuf_code} />
                <br />
                <br />
                <div >
                    <label >Auftrags Code:
                        <input
                            type="text"
                            name="auftrags_code"
                            value={formData.auftrags_code}
                            required={true}
                            onChange={(event) => setFormData({ ...formData, auftrags_code: event.target.value })}
                        />
                    </label>
                </div>
                <div>
                    <label >Versandbahnhof:
                        <input
                            type="text"
                            name="vbf"
                            value={formData.versandbanhof}
                            readOnly
                        />
                    </label>
                </div>
                <div>
                    <label >Versandbahnhof Nr.:
                        <input
                            type="text"
                            name="vbf_nr"
                            value={formData.vbf_nr}
                            required={true}
                            onChange={(event) => setFormData({ ...formData, vbf_nr: event.target.value })}
                        />
                    </label>
                </div>
                <div >
                    <label >Lieferort:
                        <input
                            type='text'
                            name="deliver_place"
                            value={formData.deliver_place}
                            required={true}
                            onChange={(event) => setFormData({ ...formData, deliver_place: event.target.value })}
                        />
                    </label>
                </div>
                <div>
                    <label >Empfänger:
                        <input
                            type="text"
                            name="counterpart"
                            value={formData.counterpart}
                            required={true}
                            onChange={(event) => setFormData({ ...formData, counterpart: event.target.value })}
                        />
                    </label>
                </div>
                <div>
                    <label >Verwiegung:
                        <input
                            type="text"
                            name="verwiegung"
                            value={formData.verwiegung}
                            required={false}
                            readOnly
                        />
                    </label>
                </div>

                <div>
                    {formData.wagons.map((wagon, index) => (
                        <CompleteEmailFormWagons key={index} index_={index} handleChange={handleChange} formData={wagon}>
                            <Button variant="outline-primary" size='sm' onClick={addWagon}>
                                Weiteres Wagenformular hinzufügen
                            </Button>
                            {formData.wagons.length > 1 && ( // To ensure at least one place is present before allowing deletion
                                <Button variant="outline-danger" size='sm' onClick={() => { deleteWagon(index) }}>
                                    Wagenformular löschen
                                </Button>
                            )}
                        </CompleteEmailFormWagons>
                    ))}
                </div>

                <div >
                    <label >Vermeke des Absenders:
                        <ReactTextareaAutocomplete
                            className="remarks_textarea"
                            loadingComponent={() => <span>Loading...</span>}
                            minChar={0}
                            value={formData.remarks}
                            trigger={{
                                " ": {
                                    dataProvider: (token) =>
                                        formData.wagons.filter((item) =>
                                            item.produkt.toLowerCase().startsWith(token.toLowerCase())
                                        ),
                                    component: ({ entity: { produkt } }) => <div>{produkt}</div>,
                                    output: (item) => item.produkt,
                                }
                            }}
                            onChange={(event) => setFormData({ ...formData, remarks: event.target.value })}
                        />
                    </label>
                </div>

                <div >
                    <label >Versanddatum:
                        <input
                            type="datetime-local"
                            name="date_load"
                            value={formData.date_load}
                            required={true}
                            onChange={(event) => setFormData({ ...formData, date_load: event.target.value })}
                        />
                    </label>
                </div>

                <div>
                    <label >Verwiegung Bahnhof Code: </label>
                    <input
                        type="text"
                        name='weighing_station'
                        value={formData.weighing_station}
                        onChange={(event) => { validator(event.target.value, setStationError); setFormData({ ...formData, weighing_station: event.target.value }); }}
                        onBlur={() => validator(formData.weighing_station, setStationError)}
                    />
                </div>
                <div>{StationError &&
                    <ul>
                        <li key={StationError} style={{ color: 'red' }} >{StationError}</li>
                    </ul>
                }
                </div>

                <div >
                    <label >Zulaufnummer:
                        <input
                            type="text"
                            name="zulauf_nr"
                            value={formData.zulauf_nr}
                            onChange={(event) => setFormData({ ...formData, zulauf_nr: event.target.value })}
                        />
                    </label>
                </div>

                <div >
                    <label >Lieferanten-Nr:
                        <input
                            type="text"
                            name="lieferant_nr"
                            value={formData.lieferant_nr}
                            onChange={(event) => setFormData({ ...formData, lieferant_nr: event.target.value })}
                        />
                    </label>
                </div>

                <div className="form-actions">{children}</div>

            </form>
        </>
    )
}